<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title"> Edit driver details </h4>
      <form action="" method="post" @submit.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="name">Driver name</label>
              <input type="text" v-model="name" class="form-control" id="name" placeholder="enter driver name here"
                     required>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="dossier">Dossier</label>
              <input type="text" v-model="dossier" class="form-control" id="dossier"
                     placeholder="enter driver dossier number here" required>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="phone">Driver Phone</label>
              <input type="text" v-model="phone" class="form-control" id="phone"
                     placeholder="enter driver phone number here" required>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="phone">Driver Email</label> <span class="ml-5 text-primary"><a
                @click="update_email = !update_email">Change email</a></span>
              <input type="text" :disabled="!update_email" v-model="email" class="form-control" id="email"
                     placeholder="enter driver email_address" required>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <button class="btn btn-primary" type="submit">submit update</button>
            </div>
          </div>
        </div>
      </form>

    </div>
  </div>
</template>

<script>
export default {
  name: "add-new",
  data() {
    return {
      name: '',
      dossier: '',
      phone: '',
      email: '',
      update_email: false
    }
  },

  methods: {

    handleSubmit() {
      const payload = {
        'name': this.name,
        'dossier': this.dossier,
        'phone': this.phone,
        'email': this.email,
        'update_email': this.update_email,
      }

      const id = this.$route.params.id
      this.$store.dispatch('updateDriver', {payload, id});

    }
  },
  watch: {
    '$store.getters.GET_SELECTED_DRIVER': function (user) {
      this.name = user.name
      this.dossier = user['driver']['dossier'];
      this.phone = user.phone;
      this.email = user.email;
    }
  },
  created() {
    this.$store.dispatch('setPageTitle', 'Edit Driver');
    const id = this.$route.params.id
    this.$store.dispatch('getDriver', id);
  }
}
</script>

<style scoped>

</style>